import { template as template_eaa742460e9146e481b69512e79d0079 } from "@ember/template-compiler";
const SidebarSectionMessage = template_eaa742460e9146e481b69512e79d0079(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
