import { template as template_73991f7f1ebd4d0cab8a7fdf1e288a29 } from "@ember/template-compiler";
const WelcomeHeader = template_73991f7f1ebd4d0cab8a7fdf1e288a29(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
